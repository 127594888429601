<template>
  <v-row align="center">
    <v-col cols="12">
      <v-parallax :src="heroImage" min-height="85vh" class="overflow-hidden">
        <v-container>
          <v-row align="center" justify="center" class="white--text">
            <v-col cols="12" md="8" class="text-center">
              <h1 class="display-1 font-weight-black mb-4">
                {{ heroTitle }}
              </h1>
              <p class="subtitle-1">
                {{ heroSubtitle }}
              </p>
              <v-btn color="primary" class="px-5 mt-5" large href="#" to="/">
                {{ heroButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </v-col>
    <v-col cols="12">
      <table border="1" width="100%" style="border-collapse: collapse">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Country</th>
          <th>City</th>
          <th>Job</th>
        </tr>

        <tr v-for="contact in contacts" :key="contact.id">
          <td>{{ contact.name }}</td>
          <td>{{ contact.email }}</td>
          <td>{{ contact.country }}</td>
          <td>{{ contact.city }}</td>
          <td>{{ contact.job }}</td>
        </tr>
      </table>
    </v-col>
    <v-col cols="12">
      <br />

      <form>
        <label>Name</label>
        <input type="text" name="name" v-model="name" />
        <br />
        <label>Email</label>
        <input type="email" name="email" v-model="email" />
        <br />
        <label>Country</label>
        <input type="text" name="country" v-model="country" />
        <br />
        <label>City</label>
        <input type="text" name="city" v-model="city" />
        <br />
        <label>Job</label>
        <input type="text" name="job" v-model="job" />
        <br />
        <v-btn type="button" @click="createContact()" value="Add">Add</v-btn>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    heroImage: "https://picsum.photos/1400/600",
    heroTitle: "Mafikeng Accomodation",
    heroSubtitle: "Comfortable and Convenient Living for Mafikeng Students",
    heroButtonText: "Go back",
    name: "",
    email: "",
    country: "",
    city: "",
    job: "",
    contacts: [],
  }),
  mounted: function () {
    console.log("Hello from Vue!");
    this.getContacts();
  },

  methods: {
    getContacts: function () {
      axios
        .get("api/contacts.php")
        .then(function (response) {
          console.log(response.data);
          this.contacts = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    createContact: function () {
      console.log("Create contact!");

      let formData = new FormData();
      console.log("name:", this.name);
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("city", this.city);
      formData.append("country", this.country);
      formData.append("job", this.job);

      var contact = {};
      formData.forEach(function (value, key) {
        contact[key] = value;
      });

      axios({
        method: "post",
        url: "api/contacts.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          this.contacts.push(contact);
          this.resetForm();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    },
    resetForm: function(){
        this.name = '';
        this.email = '';
        this.country = '';
        this.city = '';
        this.job = '';
    },
  },
};
</script>
