import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import checkView from 'vue-check-view'
Vue.use(checkView)

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#D32F2F',
            secondary: '#A3C4BC',
            accent: '#4A6D7C',
            error: '#b71c1c',
          },
        },
      },
});