<template>
  <v-app>
    <v-app-bar
      app
      dark
      flat
      absolute
      min-height="84"
      color="#ff9d9d"
    >
      <v-container class="pa-0 pt-2 fill-height d-flex justify-center align-center">
        <div class="d-flex  pa-2">
          <router-link class="d-flex" to="/">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="@/assets/logoTEXT.png"
              transition="scale-transition"
              width="72"
            />

            <v-img
              alt="Vuetify Name"
              class="shrink mt-1 hidden-sm-and-down"
              contain
              min-width="100"
              src="@/assets/logoTEXTLOGO.png"
              width="148"
            />
          </router-link>
        </div>

        <v-spacer></v-spacer>

        <div class="text-center hidden-sm-and-down">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="font-weight-bold pt-1 "
              >
                Accommodation <v-icon class="ml-2">mdi-city</v-icon>
              </v-btn>
            </template>
            <v-list
              style="background: linear-gradient(86deg,rgba(89, 81, 81, 1) 0%,rgba(181, 72, 69, 1) 49%,rgba(249, 57, 57, 1) 100%  
                );
              "
            >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  :to="item.route"
                  :disabled="item.disabled"
                >
                  <v-list-item-title class="white--text">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
    <!-- <v-navigation-drawer>
      <v-toolbar flat>
        <v-list>
          <v-list-tile>
            <v-list-tile-title class="title">
              Application
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dense class="pt-0">
        
      </v-list>
    </v-navigation-drawer> -->

    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <v-img src="@/assets/rect36866.png" eager></v-img>
    <v-footer height="auto" class="primary lighten-1 pt-8 pb-0 mb-0">
      <v-row class="mb-0 pb-0" justify="center">
        <v-col cols="12" md="3">
          <v-btn dark text class="mr-2">Home</v-btn>
          <v-btn dark text class="mr-2">Garankuwa Acc</v-btn>
          <v-btn disabled dark text class="mr-2">Mafikeng Acc</v-btn>
        </v-col>
        <v-col cols="12" md="3"></v-col>
        <v-col cols="12" md="3"></v-col>
        <v-col class="d-flex pr-4 pb-4" cols="12" md="3">
          <div
            class="ml-0 ml-md-auto pa-2 white"
            style="max-height: fit-content; border-radius: 16px"
          >
            <v-img
              class="mx-auto pb-12"
              contain
              max-width="100px"
              src="@/assets/logoTEXTLOGO.png"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    items: [
      { title: "Garankuwa", route: "/garankuwa" , disabled: false},
      { title: "Mafikeng (Coming soon)", route: "/mafikeng", disabled: true },
      { title: "Student Hub", route: "/students", disabled: false },
    ],
    //
  }),
  methods: {
    homeButton() {},
  },
};
</script>
