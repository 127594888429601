<template>
  <v-app>
    <div
    class="py-8"
      style="background: linear-gradient(180deg, rgba(255,157,157,1) 5%, rgba(215,81,81,1) 50%, rgba(175,31,31,1) 93%);.curved {
  position: relative;
  border-bottom-left-radius: 20% 20%;
  border-bottom-right-radius: 20% 20%;
}"
    >
      <v-row class="fill-height mx-4 " align="center" justify="center">
        <v-col cols="12" class="text-center">
          <v-img eager contain max-height="320px" src="@/assets/path1271.png"></v-img>
          <h3 class="white--text">
            Building a Better Living Experience for Students
          </h3>
        </v-col>
      </v-row>
    </div>
    <div style="min-height: 15vh"></div>

    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="6" class="fluid">
          <v-row>
            <v-col class="align-center justify-center" cols="12" sm="6">
              <v-img
                class="mx-auto"
                height="400"
                style="
                  border-radius: 12px;
                  box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.51);
                "
                src="@/assets/garanRooms/room1.jpg"
              ></v-img>
            </v-col>
            <v-col cols="12" sm="6">
              <v-img
                height="400"
                class="mx-auto"
                style="
                  border-radius: 12px;
                  box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.51);
                "
                src="@/assets/garanRooms/room2.jpg"
              ></v-img>
            </v-col>
            <v-col cols="12" sm="6">
              <v-img
                height="400"
                class="mx-auto"
                style="
                  border-radius: 12px;
                  box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.51);
                "
                src="@/assets/garanRooms/room3.jpg"
              ></v-img>
            </v-col>
            <v-col cols="12" sm="6">
              <v-img
                height="400"
                class="mx-auto"
                style="
                  border-radius: 12px;
                  box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.51);
                "
                src="@/assets/garanRooms/room4.jpg"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="ml-md-8" style="max-width: 720px" cols="12" sm="6">
          <h1 class="primary--text text-md-h3 mb-md-4 font-weight-bold">About Student City - Garankuwa Heights</h1>
          <p class="font-weight-bold">
            Student City Garankuwa Heights is a student accommodation designed
            with the modern student in mind. Our units offer a comfortable and
            safe living experience, with each unit housing 3-4 students and
            featuring their own kitchen and bathroom for privacy and
            independence.
          </p>
          <v-divider class="mb-4" />
          <h3>Free WiFi for Students</h3>
          <p>
            Student success is our top priority, which is why we offer a free
            WiFi package for all of our residents. Stay connected and complete
            your coursework with ease, no matter where you are in the
            accommodation.
          </p>
          <h3>24/7 Security for Student Safety</h3>
          <p>
            At Student City Garankuwa Heights, the safety and security of our
            students is our number one priority. Our accommodation is fenced off
            and has 24/7 security to provide peace of mind for our residents.
          </p>
          <h3>Stay Fit with Our Outdoor Gym</h3>
          <p>
            Maintaining a healthy lifestyle is essential for academic success.
            That's why Student City Garankuwa Heights has an outdoor gym,
            equipped with the latest fitness equipment, for our residents to
            use.
          </p>
          <h3>Convenient Kiosk and Laundry Services</h3>
          <p>
            In addition to our outdoor gym, Student City Garankuwa Heights also
            offers a kiosk for students to purchase non-perishable items and
            laundry services. We strive to provide everything our residents need
            for a comfortable and convenient living experience.
          </p>
          <p>
            Student City Garankuwa Heights is the perfect choice for students
            who are looking for a secure, comfortable, and convenient place to
            call home. Contact us today to learn more about our student
            accommodation in Garankuwa.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <div style="min-height: 15vh"></div>
  </v-app>
</template>

<script>
export default {};
</script>

<style scoped>
.fill-height {
  height: 400px;
}
</style>
